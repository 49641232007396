import { useQuery, useQueryClient } from "react-query";

import request from "../../../request";
import { camelCase } from "lodash";
import { inboundUnitCipher } from "../../../../context/UnitsContext";

import {
  bugsnagPostgrestErrorHandler,
  recursiveCamelCaseCipher,
} from "../../../postgrestApi/common";

import { VITE_SITE_SERVICE_BASE_URL } from "../../../../env";

import {
  AllEnergyStarUtilityTypes,
  EnergyStarWaterUtility,
} from "../../../../types";

const getUtilityTypes = (
  siteId: string | number
): Promise<Array<AllEnergyStarUtilityTypes>> =>
  request
    .get(`${VITE_SITE_SERVICE_BASE_URL}/sites/${siteId}/utility_types`)
    .then(({ body }) =>
      recursiveCamelCaseCipher(body).map(
        (utility: AllEnergyStarUtilityTypes) => ({
          ...utility,
          name: camelCase(utility.name),
          defaultUnitName: inboundUnitCipher[utility.defaultUnitName],
        })
      )
    )
    .catch(bugsnagPostgrestErrorHandler);

const UTILITY_TYPES_KEY = "getUtilityTypes";
export const useGetUtilityTypes = (siteId: number) =>
  useQuery([UTILITY_TYPES_KEY, siteId], () => getUtilityTypes(siteId), {
    // Convert waste water type to water
    select: (types): AllEnergyStarUtilityTypes[] => {
      const wasteWaterType = types.find(({ name }) => name === "wasteWater");

      if (!wasteWaterType) {
        return types;
      }

      const waterType = types.find(({ name }) => name === "water");

      if (waterType) {
        return types.filter((type) => type !== wasteWaterType);
      }

      return types.map((type) =>
        type === wasteWaterType
          ? ({ ...type, name: "water" } as EnergyStarWaterUtility)
          : type
      );
    },
  });

const getUtilityTypesQueryKey = (siteId: number) => [UTILITY_TYPES_KEY, siteId];

export const useInvalidateUtilityTypesQuery = () => {
  const queryClient = useQueryClient();
  return (siteId: number) =>
    queryClient.invalidateQueries(getUtilityTypesQueryKey(siteId));
};
