import { Disposition } from "@src/backpack-console/pages/SiteDashboard/Documents/types";
import {
  useUtilitiesArcadiaQAHeader,
  UtilitiesRouteToHeader,
} from "@src/utilitiesArcadiaQA";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FetchClients, { ClientOutput } from "../client";

type ManualBill = ClientOutput["utilities"]["ManualBill"];

const manualBill = {
  useQueryAllRecentlyUploaded,
  useQueryAllSuccessful,
  useQueryAll,
  getAllSuccessfulManualBills,
  useDeleteManualBills,
  usePatchManualBill,
  useInvalidateManualBillQueries,
  downloadManualBill,
} as const;

export default manualBill;

const MANUAL_BILL_KEY = "utilities/manual-bills";
async function getAllManualBills(
  site_id: number,
  statuses: ManualBill["status"][],
  routeToHeader: UtilitiesRouteToHeader
) {
  const { data, error } = await FetchClients.utilities.GET(
    "/{site_id}/manual_bills",
    {
      params: {
        path: {
          site_id,
        },
        query: {
          statuses,
        },
      },
      headers: {
        "X-Route-To": routeToHeader,
      },
    }
  );
  if (data) return data;
  throw new Error(error);
}

function useQueryAll(site_id: number, statuses: ManualBill["status"][]) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useQuery([MANUAL_BILL_KEY, site_id, statuses], () =>
    getAllManualBills(site_id, statuses, routeToHeader)
  );
}

async function deleteManualBill({
  manual_bill_id,
  site_id,
  routeToHeader,
}: {
  manual_bill_id: number;
  site_id: number;
  routeToHeader: UtilitiesRouteToHeader;
}) {
  return FetchClients.utilities.DELETE(
    "/{site_id}/manual_bills/{manual_bill_id}",
    {
      params: {
        path: {
          site_id: site_id,
          manual_bill_id,
        },
      },
      headers: {
        "X-Route-To": routeToHeader,
      },
    }
  );
}

function useDeleteManualBills(site_id: number) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();
  const invalidateQueries = useInvalidateManualBillQueries(site_id);
  return useMutation({
    mutationFn: async (manualBillIds: number[] | number) => {
      const manual_bill_ids = Array.isArray(manualBillIds)
        ? manualBillIds
        : [manualBillIds];
      try {
        for (const manual_bill_id of manual_bill_ids) {
          const { error } = await deleteManualBill({
            manual_bill_id,
            site_id,
            routeToHeader,
          });
          if (error) throw new Error(error);
        }
      } catch (e) {
        throw new Error("An error occurred while deleting bill(s)", {
          cause: e,
        });
      }
    },
    onSettled: () => {
      invalidateQueries();
    },
  });
}

async function patchManualBill({
  siteId,
  fileId,
  filename,
  routeToHeader,
}: {
  siteId: number;
  fileId: number;
  filename: string;
  routeToHeader: UtilitiesRouteToHeader;
}) {
  return FetchClients.utilities
    .PATCH("/{site_id}/manual_bills/{manual_bill_id}", {
      params: {
        path: {
          manual_bill_id: fileId,
          site_id: siteId,
        },
      },
      body: { filename },
      headers: {
        "X-Route-To": routeToHeader,
      },
    })
    .then((res) => res.data);
}

function usePatchManualBill(siteId: number) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();
  const invalidateQueries = useInvalidateManualBillQueries(siteId);
  return useMutation({
    mutationFn: async ({
      fileId,
      filename,
    }: {
      fileId: number;
      filename: string;
    }) => patchManualBill({ siteId, fileId, filename, routeToHeader }),
    onSettled: () => {
      invalidateQueries();
    },
  });
}

type DownloadAttachmentsParams = {
  fileId: number;
  disposition?: Disposition;
};
async function downloadManualBill({
  disposition,
  fileId,
}: DownloadAttachmentsParams) {
  return FetchClients.d3
    .GET(`/backpack_manual_bills/download`, {
      params: {
        query: {
          file_id: fileId,
          disposition,
        },
      },
      parseAs: "text",
    })
    .then((res) => res.data);
}

// We show these on the utilities account management page
const RECENTLY_UPDATED_STATUSES: ManualBill["status"][] = [
  "failure",
  "in_progress",
  "incomplete",
];
function useQueryAllRecentlyUploaded(site_id: number) {
  return useQueryAll(site_id, RECENTLY_UPDATED_STATUSES);
}

// We show these on the documents page
const SUCCESS_STATUSES: ManualBill["status"][] = ["success"];

function useQueryAllSuccessful(site_id: number) {
  return useQueryAll(site_id, SUCCESS_STATUSES);
}

function getAllSuccessfulManualBills(
  site_id: number,
  routeToHeader: UtilitiesRouteToHeader
) {
  return getAllManualBills(site_id, SUCCESS_STATUSES, routeToHeader);
}

function useInvalidateManualBillQueries(siteId: number) {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([MANUAL_BILL_KEY, siteId]);
}
