import { useMemo } from "react";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";

import { useActiveSites } from "@src/context/SitesContext";

import { ProjectStatus } from "../../../types";
import FetchClients, { ClientOutput } from "../client";
import { getProjectsInProgressQueryKey } from "./inProgress";
import { useInvalidateEquipmentQueries } from "../equipment/useInvalidateEquipmentQueries";
import { useCurrentAccount } from "@src/backpack-console/CurrentAccountManager";

export type BackpackProject = ClientOutput["projects"]["Project"];

export type BackpackProjectRequest = ClientOutput["projects"]["ProjectRequest"];

const project = {
  useQueryAllForSite,
  useQueryAllForAccount,
  mutations: {
    usePost,
    usePatch,
    useDelete,
    usePostBulkUpdateStatus,
  },
} as const;

export default project;

export const PROJECT_KEY = "projects/project";

const getBackpackProjects = async (
  siteId: number | null,
  status?: ProjectStatus
) => {
  // query is not enabled if siteId is not a number, so error should never throw here
  if (typeof siteId !== "number") {
    throw new Error("siteId is required");
  }
  const { data, error } = await FetchClients.projects.GET("/", {
    params: {
      query: {
        site_id: siteId,
        status,
      },
    },
  });
  if (data) return data;
  throw new Error(error);
};

function useQueryAllForSite(siteId: number | null, status?: ProjectStatus) {
  return useQuery(
    [PROJECT_KEY, siteId, status],
    () => getBackpackProjects(siteId, status),
    { enabled: typeof siteId === "number" }
  );
}

function useQueryAllForAccount({
  accountId,
  status,
}: {
  accountId?: number;
  status: ProjectStatus;
}) {
  const sites = useActiveSites(accountId);
  const queries = useQueries(
    sites.map(({ id }) => ({
      queryKey: [PROJECT_KEY, id, status],
      queryFn: () => getBackpackProjects(id, status),
    }))
  );

  const data = useMemo(
    () =>
      queries
        .map((query) => query.data)
        .flat()
        .filter((i): i is BackpackProject => Boolean(i)),
    [queries]
  );

  return {
    isLoading: queries.some(({ isLoading }) => isLoading),
    data,
  };
}

function usePost(siteId: number) {
  const queryClient = useQueryClient();
  const { accountId } = useCurrentAccount();
  const invalidateEquipmentQueries = useInvalidateEquipmentQueries();
  return useMutation({
    mutationFn: async (body: BackpackProjectRequest) => {
      const { data, error } = await FetchClients.projects.POST("/", {
        body,
      });
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: async (_, req) => {
      await queryClient.invalidateQueries({
        queryKey: [PROJECT_KEY, siteId],
      });
      await invalidateEquipmentQueries({
        accountId,
        siteId,
        equipmentIds: req.linked_equipment,
      });
    },
    onError: (e) => {
      throw new Error(
        `Unable to create new project for site: ${siteId}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}

type PutProjectRequest = Partial<BackpackProjectRequest> & {
  id: number; // Project ID
};

function usePatch(accountId?: number) {
  const queryClient = useQueryClient();
  const invalidateEquipmentQueries = useInvalidateEquipmentQueries();

  return useMutation({
    mutationFn: async ({ id, ...body }: PutProjectRequest) => {
      const { response, error } = await FetchClients.projects.PATCH("/", {
        params: {
          query: {
            id,
          },
        },
        // @ts-expect-error
        body,
      });
      if (response.ok) return;
      throw new Error(error);
    },
    onSuccess: async (_, req) => {
      await queryClient.invalidateQueries({
        queryKey: [PROJECT_KEY, req.site_id],
      });
      await invalidateEquipmentQueries({
        accountId,
        siteId: req.site_id,
        equipmentIds: req.linked_equipment,
      });
    },
  });
}

function useDelete(siteId: number) {
  const queryClient = useQueryClient();
  const queryKey = [PROJECT_KEY, siteId];
  return useMutation({
    mutationFn: async (id: number) => {
      const { error, response } = await FetchClients.projects.DELETE("/", {
        params: {
          query: {
            id,
          },
        },
      });
      if (response.ok) return;
      throw new Error(error);
    },
    onMutate: async () => {
      // cancel queries
      await queryClient.cancelQueries({
        queryKey,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
}

type ProjectStatusRequest = ClientOutput["projects"]["ProjectStatusRequest"];
function usePostBulkUpdateStatus(accountId?: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (statuses: ProjectStatusRequest[]) => {
      const { error, response } = await FetchClients.projects.POST(
        "/bulk-update-status",
        {
          body: statuses,
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onMutate: async () => {
      // cancel queries
      await queryClient.cancelQueries({
        queryKey: [PROJECT_KEY],
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [PROJECT_KEY],
      });
      if (accountId) {
        await queryClient.invalidateQueries({
          queryKey: getProjectsInProgressQueryKey(accountId),
        });
      }
    },
  });
}
