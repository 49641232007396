import request from "../../../global_functions/request";
import type { ResponseError } from "superagent";
import { BackpackResponseErrorBody } from "../errors";

import { useMutation, useQuery as useReactQuery } from "react-query";
import type { User } from "@src/types";
import {
  formatDataObj,
  snakeCaseCipher,
  formatDataArray,
  recursiveCamelCaseCipher,
} from "@src/global_functions/postgrestApi";

const users = {
  useQuery,
  mutations: {
    useUpdateUser,
  },
} as const;

export default users;

const getUser = (userId: number): Promise<User | null | undefined> =>
  request
    .get("/rest/users")
    .query({ id: `eq.${userId}` })
    .then(({ body }) => recursiveCamelCaseCipher(body[0]));

function useQuery(userId: number) {
  return useReactQuery({ queryFn: () => getUser(userId) });
}

// adding a userId/siteId entry blocks the user from access to the site
const addSiteUserBlacklists = (
  blacklists: {
    siteId: number;
    userId: number;
  }[]
) =>
  request
    .post("/rest/site_user_blacklists")
    .send(formatDataArray(blacklists, snakeCaseCipher));

// deleting a userId/siteId entry enables the user to access the site
const deleteSiteUserBlacklist = ({
  siteIds,
  userId,
}: {
  siteIds: number[];
  userId: number;
}) =>
  request
    .delete("/rest/site_user_blacklists")
    .query({ site_id: `in.(${siteIds.join(",")})`, user_id: `eq.${userId}` });

// arrays of siteIds
type Blocklists = {
  postToBlocklist: number[];
  removeFromBlocklist: number[];
};

const updateUser = (userId: number, updatedProps: Partial<User>) =>
  request
    .patch("/rest/users")
    .query({ id: `eq.${userId}` })
    .send(formatDataObj(updatedProps, snakeCaseCipher, { allowNulls: true }));

interface UpdateUserError extends ResponseError {
  response: {
    body?: BackpackResponseErrorBody & { details?: string };
  } & ResponseError["response"];
}
const isUpdateUserError = (e: any): e is UpdateUserError =>
  Boolean(e?.response?.body?.details);
const updateUserAndBuildings = async (
  userId: number,
  updatedProps: Partial<User>,
  blocklists: Partial<Blocklists>
) => {
  const { postToBlocklist, removeFromBlocklist } = blocklists;
  try {
    await updateUser(userId, updatedProps);
    if (postToBlocklist?.length)
      await addSiteUserBlacklists(
        postToBlocklist.map((siteId) => ({ siteId, userId }))
      );
    if (removeFromBlocklist?.length) {
      await deleteSiteUserBlacklist({ siteIds: removeFromBlocklist, userId });
    }
  } catch (e) {
    if (isUpdateUserError(e)) {
      const message: string = e.response.body.details;
      // throw an error only if postgrest error is that email already exists
      if (/^(?=.*\bemail\b)(?=.*\balready\b)(?=.*\bexists\b).*/.test(message)) {
        throw new Error("Email has already been taken");
      }
    }
    throw new Error("Failed to update user.");
  }
};

function useUpdateUser() {
  return useMutation({
    mutationFn: ({
      userId,
      updatedProps,
      blocklists,
    }: {
      userId: number;
      updatedProps: Partial<User>;
      blocklists: Partial<Blocklists>;
    }) => updateUserAndBuildings(userId, updatedProps, blocklists),
  });
}
