import type { ResponseError } from "superagent";

export interface BackpackResponseErrorBody {
  error: {
    message: string;
    code?: number;
  };
}

// Start superagent specific handling
interface BackpackSuperagentResponseError extends ResponseError {
  response: {
    body: BackpackResponseErrorBody;
  } & ResponseError["response"];
}
function isBackpackSuperagentResponseError(
  e: any
): e is BackpackSuperagentResponseError {
  return Boolean(e?.response?.body?.error?.message);
}
export function throwBackpackSuperagentResponseError(e: any) {
  if (isBackpackSuperagentResponseError(e)) {
    // If the error is from the server, make a new error with the message from the response
    throw new Error(e.response.body.error.message);
  }

  // Otherwise, we don't know how to handle this error
  throw e;
}
// End superagent specific handling

// These can be used when handling fetch errors
export const isBackpackResponseErrorBody = (
  e: any
): e is BackpackResponseErrorBody => typeof e?.error?.message === "string";
export class BackpackServiceError extends Error {
  code: number;

  constructor(message: BackpackResponseErrorBody) {
    super(message.error.message);
    this.name = "BackpackServiceError";
    this.code = message.error.code ?? 0;
  }
}
export function throwBackpackResponseError(e: any) {
  if (isBackpackResponseErrorBody(e)) {
    // If the error is from the server, make a new error with the message from the response
    throw new BackpackServiceError(e);
  }

  // Otherwise, we don't know how to handle this error
  throw e;
}
