import { useUtilitiesArcadiaQAHeader } from "@src/utilitiesArcadiaQA";

import { useMutation, useQuery, useQueryClient } from "react-query";

import FetchClients, { ClientOutput } from "../client";

export type UtilityAccount = ClientOutput["utilities"]["UtilityAccount"];

export type UtilityAccountRequest =
  ClientOutput["utilities"]["UtilityAccountRequest"];

export type UtilityAccountNumberRequest =
  ClientOutput["utilities"]["UtilityAccountNumberRequest"];

export type UtilityAccountNameRequest =
  ClientOutput["utilities"]["UtilityAccountNameRequest"];

const accounts = {
  useQueryAll,
  usePostByAccountNumber,
  useInvalidateQueryAll,
  usePatch,
} as const;

export default accounts;

export const UTILITY_ACCOUNTS_KEY = "utilities/accounts";

function useQueryAll(siteId: number) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useQuery([UTILITY_ACCOUNTS_KEY, siteId], async () => {
    const { data, error } = await FetchClients.utilities.GET(
      "/{site_id}/accounts",
      {
        params: {
          path: {
            site_id: siteId,
          },
        },
        headers: {
          "X-Route-To": routeToHeader,
        },
      }
    );
    if (data) return [...data];
    throw new Error(error);
  });
}

// This is for creating third-party-portal accounts only
function usePostByAccountNumber(siteId: number) {
  const queryClient = useQueryClient();
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useMutation({
    mutationFn: async (account: UtilityAccountNumberRequest) => {
      const { data, error } = await FetchClients.utilities.POST(
        "/{site_id}/accounts",
        {
          params: {
            path: {
              site_id: siteId,
            },
          },
          body: account,
          headers: {
            "X-Route-To": routeToHeader,
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([UTILITY_ACCOUNTS_KEY, siteId]);
    },
  });
}

function usePatch(siteId: number) {
  const queryClient = useQueryClient();
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useMutation({
    mutationFn: async (params: { accountId: number; accountName: string }) => {
      const { data, error } = await FetchClients.utilities.PATCH(
        "/{site_id}/accounts/{account_id}",
        {
          params: {
            path: {
              site_id: siteId,
              account_id: params.accountId,
            },
          },
          body: {
            account_name: params.accountName,
          },
          headers: {
            "X-Route-To": routeToHeader,
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([UTILITY_ACCOUNTS_KEY, siteId]);
    },
  });
}

function useInvalidateQueryAll(siteId: number) {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([UTILITY_ACCOUNTS_KEY, siteId]);
  };
}
