import request from "../../request";

import { useMutation } from "react-query";
import { snakeCase } from "lodash";
import { Utility } from "@src/types";

const RESOURCE_URL = `/rest/onboarding_complete`;

const onboardingComplete = {
  mutations: {
    usePost,
    useDelete,
  },
} as const;

export default onboardingComplete;

// snake_cased completeItem must exist in intel.onboarding_complete_enum or this call will fail

export type UtilityCompleteItem = `utilities${Capitalize<
  Exclude<Utility, "wasteWater">
>}`;
type CompleteItem = "ratingsEnergyStar" | UtilityCompleteItem;

const postOnboardingComplete = (
  siteId: string | number,
  completeItem: CompleteItem
) => {
  return request
    .post(RESOURCE_URL)
    .send({ site_id: siteId, item: snakeCase(completeItem) });
};

function usePost(siteId: number) {
  return useMutation({
    mutationFn: (item: CompleteItem) => postOnboardingComplete(siteId, item),
  });
}
const deleteOnboardingComplete = (
  siteId: string | number,
  completeItem: CompleteItem
) => {
  return request
    .delete(RESOURCE_URL)
    .query({ site_id: `eq.${siteId}`, item: `eq.${snakeCase(completeItem)}` });
};

function useDelete(siteId: number) {
  return useMutation({
    mutationFn: (item: CompleteItem) => deleteOnboardingComplete(siteId, item),
  });
}
