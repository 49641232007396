import React, { ChangeEvent, forwardRef, Ref } from "react";
import classnames from "classnames";
import { InputLabel, getColor } from "../Input/Input";
import { BackgroundColor, backgroundColorMap } from "../Input/inputVariants";
import Text from "../../type/Text/Text";

type TextareaVariant = "rounded" | "line"; // this just changes the label style, there is no different style for the actual textarea
type TextareaStatus = "default" | "negative" | "positive" | "warning";

export type TextareaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "ref"
> & {
  name: string;
  dataTestId?: string;
  variant?: TextareaVariant;
  status?: TextareaStatus;
  label?: string;
  tooltip?: string;
  supportText?: string;
  validation?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  backgroundColor?: BackgroundColor;
};

const Textarea = forwardRef(function Textarea(
  {
    name,
    dataTestId,
    variant = "line", // only used for labels
    status = "default",
    label,
    tooltip,
    supportText,
    validation,
    className,
    containerClassName,
    disabled,
    onChange,
    value,
    placeholder = "",
    backgroundColor = "white",
    ...rest
  }: TextareaProps,
  ref: Ref<HTMLTextAreaElement>
) {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  React.useImperativeHandle<
    HTMLTextAreaElement | null,
    HTMLTextAreaElement | null
  >(ref, () => textareaRef.current);

  const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(evt);
  };

  return (
    <div className="w-full">
      {label && (
        <InputLabel
          label={label}
          name={name}
          variant={variant}
          tooltip={tooltip}
          disabled={disabled}
        />
      )}
      <textarea
        {...rest}
        className={classnames(
          "resize-none",
          "font-sans",
          "max-h-[300px]",
          "text-sm",
          "font-[500]",
          "p-4",
          "w-full",
          "outline-0",
          "rounded-[10px]",
          "hover:text-straps-primary",
          "hover:drop-shadow-xl",
          "active:text-straps-primary",
          "active:drop-shadow-xl",
          "placeholder:text-sm placeholder:text-straps-tertiary placeholder:transition-colors placeholder:hover:text-straps-primary placeholder:focus:text-straps-tertiary",
          className,
          {
            [backgroundColorMap[backgroundColor]]: status === "default",
            "bg-straps-positive-bg": status === "positive",
            "bg-straps-negative-bg": status === "negative",
            "bg-straps-warning-bg": status === "warning",
            "text-straps-tertiary": disabled,
            "pointer-events-none cursor-not-allowed": disabled,
          }
        )}
        ref={textareaRef}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        data-testid={dataTestId ?? "textarea"}
      >
        {value}
      </textarea>
      {supportText && (
        <Text
          variant={variant === "line" ? "sb_t-14-500" : "sb_t-12-500"}
          className="pt-2"
          color={getColor(status, disabled)}
        >
          {supportText}
        </Text>
      )}
    </div>
  );
});

export default Textarea;
